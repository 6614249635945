import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Login from './pages/Login/Login';
import LoginExterno from './pages/Login/LoginExterno';
import Declaracion from './pages/Declaracion/Declaracion';
import Declaraciones from './pages/Declaraciones/Declaraciones';
import DeclaracionesExterno from './pages/Declaraciones/DeclaracionesExterno';
import Formulario from './pages/Formulario/Formulario';
import NewHome from './pages/NewHome/NewHome';

import useLoggedUser from './hooks/useLoggedUser';

import './App.scss';
import Environment from './providers/Environment';

const ProtectedRoute = (props) => {
  const loggedUser = useLoggedUser().loggedUser;
  if (!loggedUser || loggedUser.externo) {
    if (loggedUser?.externo) {
      return <Redirect exact path={props.path} to='/login_externo' />;
    }
    return <Redirect exact path={props.path} to='/login' />;
  }
  return <Route {...props} />;
};

const ControlRoute = (props) => {
  const loggedUser = useLoggedUser().loggedUser;
  if (
    !loggedUser ||
    !loggedUser.roles.filter((role) => {
      return (
        role.key === Environment.rolAdministrador ||
        role.key === Environment.rolMesaEntradas
      );
    }).length
  ) {
    return <Redirect exact path={props.path} to='/' />;
  }
  return <Route {...props} />;
};

const ControlExternoRoute = (props) => {
  const loggedUser = useLoggedUser().loggedUser;
  if (loggedUser && loggedUser.externo) {
    return <Route {...props} />;
  }
  return <Redirect exact path={props.path} to='/login' />;
};

const App = () => {
  return (
    <div className='wrapper'>
      <BrowserRouter>
        <Switch>
          <Route exact path='/login' component={Login} />
          <Route exact path='/login_externo' component={LoginExterno} />
          <ProtectedRoute exact path='/' component={NewHome} />
          <ProtectedRoute
            exact
            path='/declaraciones/:declaracion?'
            component={Declaracion}
          />
          <ProtectedRoute
            exact
            path='/formularios/:formulario?'
            component={Formulario}
          />
          <ControlRoute
            exact
            path='/declaraciones_control'
            component={Declaraciones}
          />
          <ControlExternoRoute
            exact
            path='/declaraciones_control_externo'
            component={DeclaracionesExterno}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
