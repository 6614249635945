import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import themeDDJJ from "../themes/themeDDJJ";

const DeclaracionHeaderPaper = ({ className, title, value }) => {
  return (
    <Grid item xs={12} sm={6} lg={3}>
      <Paper elevation={0} className={className}>
        <label>{title}</label>
        <Typography color="textPrimary">{value}</Typography>
      </Paper>
    </Grid>
  );
};

const DeclaracionHeader = ({ declaracion }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    bloqueHeader: {
      background: "#f0f0f0",
      borderRadius: "5px",
      marginTop: "20px",
      marginBottom: "20px",
      padding: "10px",
    },
    paper: {
      textAlign: "left",
      color: themeDDJJ.palette.secondary.main,
      backgroundColor: "transparent",
      fontSize: ".8rem",
      paddingTop: "10px",
      
    },
    destacada: {
      textAlign: "left",
      color: themeDDJJ.palette.secondary.main,
      backgroundColor: "transparent",
      padding: "10px",
      '& p': {
        fontSize: '2rem',
        color: themeDDJJ.palette.primary.light,
        fontWeight: '300',
      },
    }
  }));
  const classes = useStyles();
  return (
      <Grid container spacing={0} className={classes.bloqueHeader}>
        <Grid item container spacing={0} lg={3} >
          <DeclaracionHeaderPaper
            className={classes.destacada}
            title="TIPO"
            value={declaracion?.tipo}
          />
        </Grid>
        <Grid item container spacing={1} lg={9}>
          <DeclaracionHeaderPaper
            className={classes.paper}
            title="NOMBRE COMPLETO"
            value={declaracion?.nombre_completo}
          />
          <DeclaracionHeaderPaper
            className={classes.paper}
            title="CARGO / FUNCIÓN"
            value={declaracion?.cargo_funcion}
          />
          <DeclaracionHeaderPaper
            className={classes.paper}
            title="VÍNCULO"
            value={declaracion?.vinculo}
          />
          <DeclaracionHeaderPaper
            className={classes.paper}
            title={declaracion?.tipo_documento}
            value={declaracion?.numero_documento}
          />
          <DeclaracionHeaderPaper
            className={classes.paper}
            title="CUIT/CUIL"
            value={declaracion?.cuil}
          />
          <DeclaracionHeaderPaper
            className={classes.paper}
            title="LEGAJO"
            value={declaracion?.legajo}
          />
          <DeclaracionHeaderPaper
            className={classes.paper}
            title="ESTADO CIVIL"
            value={declaracion?.estado_civil}
          />
        </Grid>
      </Grid>
  );
};

export default DeclaracionHeader;
