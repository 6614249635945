import { useEffect, useState } from 'react';

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import MaterialTable from 'material-table';

import ContenidoLoading from '../../components/ContenidoLoading';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import useBackendApi from '../../hooks/useBackendApi';
import tableConstants from '../../hooks/tableConstants';
import useDatosUsuario from '../../hooks/useDatosUsuario';

import colors from '../../themes/coloresDDJJ';
import themeDDJJ from '../../themes/themeDDJJ';

import './Declaraciones.scss';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    color: '#000',
    background: '#fff',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  user: {
    color: '#2A8395',
    fontWeight: 'bold',
    fontStretch: 'extra-condensed',
  },
  bloques: {
    padding: '15px',
    backgroundColor: 'white',
    borderRadius: '5px',
    marginBottom: '20px',
  },
  bloqueSolo: {
    padding: '40px',
    backgroundColor: 'white',
    borderRadius: '5px',
    marginBottom: '20px',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: themeDDJJ.palette.secondary.main,
  },
  submitProgress: {
    color: themeDDJJ.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const Declaraciones = () => {
  const [loading, setLoading] = useState(false);
  const [disabledActions, setDisabledActions] = useState(false);
  const datosUsuario = useDatosUsuario().datosUsuario;
  const [declaracionesControl, setDeclaracionesControl] = useState([]);
  const { getDeclaracionesControl, getFormulariosControl } = useBackendApi();
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    getDeclaracionesControl(
      (res) => {
        if (res?.status === true) {
          Object.values(res.data);
          setDeclaracionesControl(Object.values(res?.data));
        }
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  }, []);

  const handleDownloadFormularios = async (ev, row) => {
    setDisabledActions(true);
    const { user_id, anio } = row;
    const data = await getFormulariosControl(user_id, anio);
    const linkSource = `data:text/plain;base64,${data?.formularios}`;
    const downloadLink = document.createElement('a');
    const fileName = `DeclaraciónJuradaPatrimonial_${user_id}_${anio}.zip`;
    setDisabledActions(false);
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const options = {
    actionsColumnIndex: -1,
    paging: false,
    exportAllData: true,
    exportButton: true,
    exportFileName: 'Declaraciones_Control',
    headerStyle: {
      backgroundColor: colors.verde,
      color: '#ffffff',
    },
  };
  const columns = [
    { title: 'UserId', field: 'user_id', hidden: true },
    { title: 'Año', field: 'anio' },
    { title: 'Tipo', field: 'tipo' },
    { title: 'Agente', field: 'agente' },
    { title: 'Legajo', field: 'legajo' },
    { title: 'Cargo / Función', field: 'cargo_funcion' },
    { title: 'Dependencia', field: 'dependencia' },
    {
      title: 'Fecha Presentación',
      field: 'fecha_firma',
      render: (rowData) => new Date(rowData.fecha_firma).toLocaleDateString(),
    },
    {
      title: 'DDJJ Relacionadas',
      field: 'familiares',
      render: (rowData) => (
        <ul>
          {rowData.familiares.map((declaracionFamiliar) => (
            <li>{`Nombre: ${declaracionFamiliar.familiar} - Tipo Declaración: ${declaracionFamiliar.tipo} - CUIL/CUIT: ${declaracionFamiliar.cuil} - Parentesco: ${declaracionFamiliar.parentesco}`}</li>
          ))}
        </ul>
      ),
      export: false,
    },
  ];
  const actions = [
    {
      icon: tableConstants.icons.Archive,
      tooltip: 'Descargar',
      onClick: handleDownloadFormularios,
      disabled: disabledActions,
    },
  ];
  return (
    <>
      <div className={classes.root}>
        <main className={classes.content}>
          <Header classes={classes} datosUsuario={datosUsuario} />
          <div className={classes.appBarSpacer} />
          <Container maxWidth='lg' className={classes.container}>
            {loading ? (
              <ContenidoLoading />
            ) : (
              <MaterialTable
                title='Listado de Control DDJJs'
                icons={tableConstants.icons}
                columns={columns}
                data={declaracionesControl}
                /*
                data={(query) =>
                  new Promise((resolve, reject) => {
                    getDeclaracionesControlPaginacion(
                      (res) => {
                        if (res?.status === true) {
                          resolve({
                            // data: res?.data,
                            data: res?.data ? Object.values(res.data) : [],
                            page: query.page,
                            pageSize: query.pageSize,
                            totalCount: res?.meta?.total,
                          });
                        }
                        setLoading(false);
                      },
                      (error) => {
                        console.log(error);
                        setLoading(false);
                      },
                      query
                    );
                  })
                }
                */
                actions={actions}
                options={options}
                localization={tableConstants.localization}
              />
            )}
          </Container>
          <Footer />
        </main>
      </div>
    </>
  );
};

export default Declaraciones;
