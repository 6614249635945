import { useState } from "react";
import useEncryptStorage from "./useEncryptStorage";

export default function useLoggedUser() {
  const encryptStorage = useEncryptStorage();

  const getLoggedUser = () => {
    const loggedUser = encryptStorage.getItem("loggedUser");
    return JSON.parse(loggedUser);
  };
  const [loggedUser, setLoggedUser] = useState(getLoggedUser());

  const saveLoggedUser = (user) => {
    if (!user) {
      encryptStorage.removeItem("loggedUser");
      encryptStorage.removeItem("datosUsuario");
    } else {
      encryptStorage.setItem("loggedUser", JSON.stringify(user));
    }
    setLoggedUser(user);
  };

  return {
    setLoggedUser: saveLoggedUser,
    loggedUser,
  };
}
