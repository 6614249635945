const colors =  {
   

    verde_claro: "#309A80",
    verde : "#008072",
    verde_oscuro : "00695f",

    azul_claro: '#2272CE',
    azul : "#006370",
    azul_oscuro: '#00414A',
       
    rojo: "#f50057",

    naranja: "#ff9900",
}

export default colors;