import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AddIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { Alert } from '@material-ui/lab';

import Swal from 'sweetalert2';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import DeclaracionesPendientes from '../../components/DeclaracionesPendientes';
import DeclaracionesFinalizadas from '../../components/DeclaracionesFinalizadas';
import DeclaracionNueva from '../../components/DeclaracionNueva';
import Environment from '../../providers/Environment';

import useBackendApi from '../../hooks/useBackendApi';
import useDatosUsuario from '../../hooks/useDatosUsuario';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const NewHome = () => {
  const drawerWidth = 240;
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      color: '#000',
      background: '#fff',
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
    containerAlert: {
      paddingTop: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
    user: {
      color: '#2A8395',
      fontWeight: 'bold',
      fontStretch: 'extra-condensed',
    },
    contenedorTabs: {
      backgroundColor: '#f5f5f5',
      background:
        'linear-gradient(180deg, rgb(247 247 247) 0%, rgb(251 251 250) 95%)',
    },
  }));
  const classes = useStyles();
  const [value, setValue] = useState(2);

  const { datosUsuario, setDatosUsuario } = useDatosUsuario();
  const { getDatosUsuarioMpf } = useBackendApi();
  useEffect(() => {
    if (!datosUsuario) {
      getDatosUsuarioMpf(
        (userData) => {
          if (userData?.status) {
            setDatosUsuario(userData.data);
          }
        },
        (error) => {
          Swal.fire('Error obteniendo información', error, 'error');
        }
      );
    }
  }, [datosUsuario]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <Header classes={classes} datosUsuario={datosUsuario} />
        <div className={classes.appBarSpacer} />
        <Container maxWidth='lg'>
          <div
            hidden={datosUsuario?.certificado.length || true}
            className={classes.containerAlert}
          >
            <Alert severity='error'>{Environment.leyendaNoCertificado}</Alert>
          </div>
        </Container>
        <Container maxWidth='lg' className={classes.container}>
          <Paper className={classes.contenedorTabs}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor='primary'
              textColor='primary'
              variant='fullWidth'
            >
              {/*
              <Tab
                label="Nueva Declaración"
                icon={<AddIcon />}
                {...a11yProps(0)}
              />
              <Tab
                label="Declaraciones En Proceso"
                icon={<EditIcon />}
                {...a11yProps(1)}
              />
              */}
              <Tab
                label='Declaraciones Finalizadas'
                icon={<DoneAllIcon />}
                {...a11yProps(2)}
              />
            </Tabs>
          </Paper>
          {/*
          <TabPanel value={value} index={0}>
            <DeclaracionNueva
              classes={classes}
              setValue={setValue}
              datosUsuario={datosUsuario}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DeclaracionesPendientes setValue={setValue} />
          </TabPanel>
          */}
          <TabPanel value={value} index={2}>
            <DeclaracionesFinalizadas datosUsuario={datosUsuario} />
          </TabPanel>
        </Container>
        <Footer />
      </main>
    </div>
  );
};

export default NewHome;
