import React from "react";

import Container from "@material-ui/core/Container";
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import Skeleton from "@material-ui/lab/Skeleton";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    contenedorLoading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      },
      imagenLoading:{
        color: "#00414A",
        opacity: "0.9",
      },
});

function ContenidoLoading() {
  const classes = useStyles();
  return (
    <Container maxWidth="lg">
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <br></br>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <br></br>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Modal open>
            <div className={classes.contenedorLoading}>
                <CircularProgress className={classes.imagenLoading}/>
            </div>
        </Modal>
    </Container> 
  );
}

export default ContenidoLoading;
