import React from "react";

import Box from "@material-ui/core/Box";

import FooterCopyright from "./FooterCopyright";

const Footer = () => {
  return (
    <>
      <Box pt={0}>
        <FooterCopyright />
      </Box>
    </>
  );
};

export default Footer;
