import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import { teal } from "@material-ui/core/colors";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";

import Swal from "sweetalert2";
import MaterialTable from "material-table";

import colors from "../../themes/coloresDDJJ";
import themeDDJJ from "../../themes/themeDDJJ";
import useFormulario from "../../hooks/useFormulario";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ContenidoLoading from "../../components/ContenidoLoading";
import DeclaracionHeader from "../../components/DeclaracionHeader";
import tableConstants from "../../hooks/tableConstants";
import useBackendApi from "../../hooks/useBackendApi";
import useDatosUsuario from "../../hooks/useDatosUsuario";

const Formulario = () => {
  const drawerWidth = 240;
  const history = useHistory();
  const datosUsuario = useDatosUsuario().datosUsuario;
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      color: "#000",
      background: "#fff",
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: "none",
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    fixedHeight: {
      height: 240,
    },
    user: {
      color: "#2A8395",
      fontWeight: "bold",
      fontStretch: "extra-condensed",
    },
    bloques: {
      padding: "15px",
      backgroundColor: "white",
      borderRadius: "5px",
      marginBottom: "20px",
    },
    bloqueSolo: {
      padding: "40px",
      backgroundColor: "white",
      borderRadius: "5px",
      marginBottom: "20px",
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      background: themeDDJJ.palette.secondary.main,
    },
    submitProgress: {
      color: themeDDJJ.palette.secondary.main,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    formularioContenedor: {
      background: "#ffffff",
      "& table": {
        marginBottom: "60px",
      },
      "& h6": {
        fontWeight: "400 !important",
        whiteSpace: "normal!important",
        overflow: "unset!important",
        textOverflow: "initial!important",
        fontSize: "1.1rem!important",
      },
    },
    formularioHeader: {
      textAlign: "center",
      padding: "10px 0 25px 0",
    },
    logoFormulario: {
      width: "60px",
    },
    tituloFormulario: {
      fontSize: "1rem",
      margin: "0",
    },
    contenedorBotones: {
      background: "#f0f0f0",
      display: "flex",
      justifyContent: "center",
      padding: "20px",
    },
    button: {
      margin: theme.spacing(1),
    },
    contenedorObservaciones: {
      margin: "0px 0 60px 0",
    },
  }));
  const theme = createMuiTheme({
    palette: {
      primary: teal,
    },
  });
  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(teal[500]),
      padding: "10px 30px",
      backgroundColor: teal[500],
      "&:hover": {
        backgroundColor: teal[700],
      },
    },
  }))(Button);
  const classes = useStyles();
  const [declaracion, setDeclaracion] = useState();
  const [loading, setLoading] = useState(false);
  const { formulario, setFormulario } = useFormulario();
  const location = useLocation();
  const { grabarFormulario } = useBackendApi();
  useEffect(() => {
    setDeclaracion(location.state.declaracion);
  }, [location]);
  const options = {
    actionsColumnIndex: -1,
    headerStyle: {
      backgroundColor: colors.azul,
      color: "#ffffff",
    },
    paging: false,
    search: false,
  };
  const handleGuardar = (e) => {
    e.preventDefault();
    setLoading(true);
    formulario.observaciones = document.getElementById("observaciones")?.value;
    grabarFormulario(
      formulario,
      (response) => {
        setFormulario({
          ...formulario,
          formulario,
        });
        setLoading(false);
        Swal.fire(
          response.status === true ? "Realizado" : "Error",
          response.status === true
            ? "¡El formulario se ha guardado correctamente!"
            : "Ocurrió un error grabando el formulario, intente nuevamente.",
          response.status === true ? "success" : "error"
        );
      },
      (error) => {
        console.error("Error:", error);
        setLoading(false);
      }
    );
  };
  const handleParaPresentar = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Confirme para continuar",
      icon: "warning",
      html: "Marcará el formulario como <b>'Para Presentar'</b>. Una vez que todos los formularios estén en dicho estadío, la declaración podrá Cerrarse y Firmarse.",
      showCancelButton: true,
      showCloseButton: true,
      focusConfirm: false,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        formulario.estado = "Para Presentar";
        formulario.observaciones =
          document.getElementById("observaciones")?.value;
        grabarFormulario(
          formulario,
          (response) => {
            setLoading(false);
            Swal.fire(
              response.status === true ? "Realizado" : "Error",
              response.status === true
                ? "¡Formulario marcado como 'Para Presentar' correctamente!"
                : "Ocurrió un error grabando el formulario, intente nuevamente.",
              response.status === true ? "success" : "error"
            ).then((res) => {
              setFormulario({
                ...formulario,
                formulario,
              });
              history.replace({
                pathname: "/declaraciones/" + declaracion._id,
              });
            });
          },
          (error) => {
            console.error("Error:", error);
            setLoading(false);
          }
        );
      }
    });
  };
  const handleCancelar = (e) => {
    e.preventDefault();
    history.replace({
      pathname: "/declaraciones/" + declaracion._id,
    });
  };
  return (
    <>
      <div className={classes.root}>
        <main className={classes.content}>
          <Header classes={classes} datosUsuario={datosUsuario} />
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <div className={classes.formularioContenedor}>
              <div className={classes.formularioHeader}>
                <img
                  className={classes.logoFormulario}
                  src="/images/logos/isologo-ciudad.png"
                  alt="logo"
                />
                <h3 className={classes.tituloFormulario}>
                  Consejo de la Magistratura de la Ciudad Autónoma de Buenos
                  Aires
                </h3>
                <h3 className={classes.tituloFormulario}>
                  Declaración Jurada Patrimonial Integral | {formulario?.titulo}
                </h3>
              </div>
              {loading ? (
                <ContenidoLoading />
              ) : (
                <>
                  <DeclaracionHeader declaracion={declaracion} />
                  <Grid container>
                    <Grid item xs={12}>
                      {Object.keys(
                        formulario?.incisos ? formulario?.incisos : []
                      ).map((articulo) => (
                        <MaterialTable
                          key={articulo}
                          title={formulario.incisos[articulo].titulo}
                          icons={tableConstants.icons}
                          columns={formulario.incisos[articulo].columnas}
                          data={(query) =>
                            new Promise((resolve, reject) => {
                              // const pageN = query.page + 1;
                              resolve({
                                page: query.page,
                                /*
                                  data:
                                    pageN > 0
                                      ? formulario.incisos[articulo].filas.slice(
                                          (pageN - 1) * query.pageSize,
                                          pageN * query.pageSize
                                        )
                                      : formulario.incisos[articulo].filas,
                                  */
                                data: formulario.incisos[articulo].filas,
                                totalCount:
                                  formulario.incisos[articulo].filas.length,
                              });
                            })
                          }
                          actions={[]}
                          options={options}
                          localization={tableConstants.localization}
                          editable={{
                            onRowAdd: (newData) =>
                              new Promise((resolve, reject) => {
                                for (let col in formulario.incisos[articulo]
                                  .columnas) {
                                  const field =
                                    formulario.incisos[articulo].columnas[col]
                                      .field;
                                  if (!newData[field]) {
                                    newData[field] = "";
                                  }
                                }
                                if (
                                  !Object.values(newData).every(
                                    (val) => val.length > 0
                                  )
                                ) {
                                  Swal.fire(
                                    "Error",
                                    "Debe cargar todos los campos de la fila",
                                    "error"
                                  );
                                  reject();
                                  return;
                                }
                                formulario.incisos[articulo].filas.push(
                                  newData
                                );
                                setFormulario({ ...formulario, formulario });
                                resolve();
                              }),
                            onRowUpdate: (newData, oldData) =>
                              new Promise((resolve, reject) => {
                                if (
                                  !Object.values(newData).every(
                                    (val) => val.length > 0
                                  )
                                ) {
                                  Swal.fire(
                                    "Error",
                                    "Debe cargar todos los campos de la fila",
                                    "error"
                                  );
                                  reject();
                                  return;
                                }
                                const dataUpdate = [
                                  ...formulario.incisos[articulo].filas,
                                ];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData;
                                formulario.incisos[articulo].filas = dataUpdate;
                                setFormulario({ ...formulario, formulario });
                                resolve();
                              }),
                            onRowDelete: (oldData) =>
                              new Promise((resolve, reject) => {
                                const dataDelete = [
                                  ...formulario.incisos[articulo].filas,
                                ];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                formulario.incisos[articulo].filas = dataDelete;
                                setFormulario({ ...formulario, formulario });
                                resolve();
                              }),
                          }}
                        />
                      ))}
                    </Grid>
                    <Grid item xs={12} mb={4}>
                      <TextField
                        className={classes.contenedorObservaciones}
                        margin="normal"
                        defaultValue={formulario?.observaciones}
                        fullWidth
                        id="observaciones"
                        label="Observaciones"
                        name="observaciones"
                        multiline={true}
                        rows={10}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Box className={classes.contenedorBotones}>
                    <Button
                      className={classes.button}
                      onClick={handleCancelar}
                      startIcon={<CloseIcon />}
                      size="large"
                    >
                      Volver
                    </Button>
                    <ThemeProvider theme={theme}>
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={handleGuardar}
                        startIcon={<SaveIcon />}
                        size="large"
                      >
                        Guardar avances
                      </Button>
                    </ThemeProvider>
                    <ColorButton
                      color="primary"
                      className={classes.button}
                      onClick={handleParaPresentar}
                      startIcon={<DoneAllIcon />}
                      size="large"
                    >
                      Para Presentar
                    </ColorButton>
                  </Box>
                </>
              )}
            </div>
          </Container>
          <Footer />
        </main>
      </div>
    </>
  );
};

export default Formulario;
