import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import ContenidoLoading from './ContenidoLoading';

import Swal from 'sweetalert2';

import Environment from '../providers/Environment';
import useLoggedUser from '../hooks/useLoggedUser';

import tableConstants from '../hooks/tableConstants';
import DeclaracionTabla from './DeclaracionTabla';
import colors from '../themes/coloresDDJJ';
import useBackendApi from '../hooks/useBackendApi';

const declaracionCerrada = (declaracion) => {
  const fechaCierreDt = new Date(declaracion.fecha_cierre);
  const fechaHowDt = new Date();
  return fechaCierreDt.getTime() < fechaHowDt.getTime();
};

const DeclaracionesFinalizadas = ({ datosUsuario }) => {
  const history = useHistory();
  const [declaraciones, setDeclaraciones] = useState([]);
  const [loading, setLoading] = useState(false);
  const loggedUser = useLoggedUser().loggedUser;
  const {
    getDeclaracionesFinalizadas,
    exportDeclaracion,
    printDeclaracion,
    deleteDeclaracion,
  } = useBackendApi();

  useEffect(() => {
    setLoading(true);
    getDeclaracionesFinalizadas(
      (res) => {
        if (res?.status === true) {
          setDeclaraciones(res?.data);
        }
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  }, []);

  const columns = [
    { title: 'Id', field: '_id', hidden: true },
    { title: 'Año', field: 'anio' },
    { title: 'Tipo', field: 'tipo' },
    {
      title: 'Propia',
      field: 'propia',
      render: (rowData) => (rowData.propia === true ? 'SI' : 'NO'),
    },
    { title: 'Nombre y Apellido', field: 'nombre_completo' },
    {
      title: 'Fecha Cierre',
      field: 'fecha_cierre',
      render: (rowData) => new Date(rowData.fecha_cierre).toLocaleDateString(),
    },
    { title: 'Hash', field: 'hash' },
    { title: 'Estado', field: 'estado' },
  ];
  /*
  function handleViewClick(ev, row) {
    history.push('/declaraciones/' + row._id);
  }
  function handleExportClick(ev, row) {
    let title = 'Exportar declaración jurada';
    let label = 'Ingrese su PIN del certificado de firma';
    let placeholder = 'Ingrese el PIN';
    let maxlength = Environment.maxPasswordLength;
    if (datosUsuario.certificado === '') {
      label = 'Ingrese su contraseña de red';
      placeholder = 'Ingrese la contraseña';
    }
    Swal.fire({
      title: title,
      input: 'password',
      inputLabel: label,
      inputPlaceholder: placeholder,
      confirmButtonText: 'Exportar',
      inputAttributes: {
        maxlength: maxlength,
        autocapitalize: 'off',
        autocorrect: 'off',
      },
    }).then((res) => {
      if (res.value && res.value.length) {
        setLoading(true);
        const parametros = {
          id: row._id,
          cert: datosUsuario.certificado,
          cert_pass: btoa(res.value),
        };
        exportDeclaracion(
          parametros,
          (response) => {
            setLoading(false);
            if (response?.status === true) {
              const linkSource = `data:text/plain;base64,${response?.file}`;
              const downloadLink = document.createElement('a');
              const fileName =
                'DeclaraciónJuradaPatrimonial_' +
                row._id +
                '_' +
                new Date().getTime() +
                '.enc';
              downloadLink.href = linkSource;
              downloadLink.download = fileName;
              downloadLink.click();
            } else {
              Swal.fire('Error exportando declaración', res?.message, 'error');
            }
          },
          (error) => {
            console.error('Error:', error);
            setLoading(false);
          }
        );
      }
    });
  }
  */
  function handlePrintClick(ev, row) {
    let title = 'Descargar declaración jurada';
    let label = 'Ingrese su PIN del certificado de firma';
    let placeholder = 'Ingrese el PIN';
    let errorText =
      'El PIN ingresado es incorrecto o no posee certificado de firma';
    let maxlength = Environment.maxPasswordLength;
    if (datosUsuario.certificado === '') {
      label = 'Ingrese su contraseña de red';
      placeholder = 'Ingrese la contraseña';
      errorText = 'La Contraseña ingresada es incorrecta';
    }
    Swal.fire({
      title: title,
      input: 'password',
      inputLabel: label,
      inputPlaceholder: placeholder,
      confirmButtonText: 'Descargar',
      inputAttributes: {
        maxlength: maxlength,
        autocapitalize: 'off',
        autocorrect: 'off',
      },
    }).then((resPin) => {
      if (resPin.value && resPin.value.length) {
        setLoading(true);
        fetch(Environment.apiUrl + '/1.0/firma/validar_pin_password', {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + loggedUser.api_token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            cert: datosUsuario.certificado,
            cert_pass: btoa(resPin.value),
          }),
        })
          .then((data) => data.json())
          .catch((err) => setLoading(false))
          .then((validation) => {
            setLoading(false);
            if (validation?.status) {
              setLoading(true);
              printDeclaracion(
                row._id,
                btoa(resPin.value),
                (res) => {
                  setLoading(false);
                  if (res?.status === true) {
                    const linkSource = `data:text/plain;base64,${res?.file}`;
                    const downloadLink = document.createElement('a');
                    const fileName =
                      'DeclaraciónJuradaPatrimonial_' +
                      row._id +
                      '_' +
                      new Date().getTime() +
                      '.zip';
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                  } else {
                    Swal.fire(
                      'Error imprimiendo declaración',
                      res?.message,
                      'error'
                    );
                  }
                },
                (error) => {
                  console.error('Error:', error);
                  setLoading(false);
                }
              );
            } else {
              Swal.fire('Error en validación', errorText, 'error');
            }
          });
      }
    });
  }
  /*
  function handleDuplicateClick(ev, row) {
    setLoading(true);
    const parametros = {
      id: row._id,
    };
    cloneDeclaracion(
      parametros,
      (response) => {
        setLoading(false);
        if (response?.status === true) {
          Swal.fire({
            title: '<strong>¿Está seguro?</strong>',
            icon: 'warning',
            html: "Esta acción copiará todos los formularios de la declaración y dejará la misma en estado <b>'Borrador'</b>",
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Realizar Copia',
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire(
                'Copia Exitosa',
                'Copia de declaración generada correctamente y lista para editar',
                'success'
              ).then((s) =>
                history.push('/declaraciones/' + response?.data._id)
              );
            }
          });
        } else {
          Swal.fire('Error copiando declaración', response?.message, 'error');
        }
      },
      (error) => {
        console.error('Error:', error);
        setLoading(false);
      }
    );
  }
  */
  const actions = [
    /*
    {
      icon: tableConstants.icons.Visibility,
      tooltip: "Ver",
      onClick: handleViewClick,
    },
    */
    {
      icon: tableConstants.icons.Archive,
      tooltip: 'Descargar',
      onClick: handlePrintClick,
    },
    // {
    //   icon: tableConstants.icons.Archive,
    //   tooltip: "Exportar",
    //   onClick: handleExportClick,
    // },
    // {
    //   icon: tableConstants.icons.Duplicate,
    //   tooltip: 'Generar Copia',
    //   onClick: handleDuplicateClick,
    // },
    {
      icon: tableConstants.icons.Delete,
      tooltip: 'Eliminar',
      onClick: (ev, row) => {
        if (declaracionCerrada(row)) {
          Swal.fire(
            'Declaración cerrada',
            'No puede eliminar una declaración con fecha de cierre anterior a la fecha actual',
            'error'
          );
          return;
        }
        Swal.fire({
          title: '<strong>¿Está seguro?</strong>',
          icon: 'warning',
          html: 'Esto eliminará la declaración y los formularios asociados',
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Si, eliminar',
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          if (result.isConfirmed) {
            setLoading(true);
            deleteDeclaracion(
              row._id,
              (response) => {
                setLoading(false);
                if (response?.status === true) {
                  const declaracionesFiltered = declaraciones.filter(
                    (declaracion) => {
                      return declaracion._id !== row._id;
                    }
                  );
                  setDeclaraciones(declaracionesFiltered);
                  Swal.fire(
                    'Declaración eliminada',
                    'Se eliminó correctamente la declaración (' + row._id + ')',
                    'success'
                  );
                } else {
                  Swal.fire(
                    'Error en eliminación',
                    'Ocurrió un error eliminando la declaración (' +
                      row._id +
                      '), intente nuevamente',
                    'error'
                  );
                }
              },
              (error) => {
                console.error('Error:', error);
                setLoading(false);
              }
            );
          }
        });
      },
    },
  ];
  const options = {
    actionsColumnIndex: -1,
    paging: false,
    headerStyle: {
      backgroundColor: colors.verde,
      color: '#ffffff',
    },
  };
  return (
    <>
      {loading === true ? (
        <>
          <ContenidoLoading />
        </>
      ) : (
        <DeclaracionTabla
          title='Declaraciones finalizadas'
          columns={columns}
          actions={actions}
          options={options}
          data={declaraciones}
        />
      )}
    </>
  );
};

export default DeclaracionesFinalizadas;
