import React from "react";
import { useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import DeclaracionEdicion from "../../components/DeclaracionEdicion";
import DeclaracionNueva from "../../components/DeclaracionNueva";

import themeDDJJ from "../../themes/themeDDJJ";
import useDatosUsuario from "../../hooks/useDatosUsuario";

const Declaracion = () => {
  const drawerWidth = 240;
  const datosUsuario = useDatosUsuario().datosUsuario;
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      color: "#000",
      background: "#fff",
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: "none",
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    fixedHeight: {
      height: 240,
    },
    user: {
      color: "#2A8395",
      fontWeight: "bold",
      fontStretch: "extra-condensed",
    },
    bloques: {
      padding: "15px",
      backgroundColor: "white",
      borderRadius: "5px",
      marginBottom: "20px",
    },
    bloqueSolo: {
      padding: "40px",
      backgroundColor: "white",
      borderRadius: "5px",
      marginBottom: "20px",
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      background: themeDDJJ.palette.secondary.main,
    },
    submitProgress: {
      color: themeDDJJ.palette.secondary.main,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  }));
  const classes = useStyles();
  const { declaracion } = useParams();

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <Header classes={classes} datosUsuario={datosUsuario} />
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {declaracion ? (
            <DeclaracionEdicion
              declaracionId={declaracion}
              classes={classes}
            />
          ) : (
            <DeclaracionNueva classes={classes} />
          )}
        </Container>
        <Footer />
      </main>
    </div>
  );
};

export default Declaracion;
