import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

import Alert from '@material-ui/lab/Alert';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import ReCAPTCHA from 'react-google-recaptcha';
import Swal from 'sweetalert2';

import FooterCopyright from '../../components/FooterCopyright';

import Environment from '../../providers/Environment';

import themeDDJJ from '../../themes/themeDDJJ';
import useLoggedUser from '../../hooks/useLoggedUser';

const LoginExterno = () => {
  const setLoggedUser = useLoggedUser().setLoggedUser;
  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    loginContenedor: {
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '40px',
      paddingBottom: '30px',
      background: 'white',
      borderRadius: '10px',
      alignSelf: 'center',
    },
    title: {
      fontSze: '1.2rem',
      color: '#00695f',
      textAlign: 'center',
      marginBottom: '-1rem',
      marginTop: '2rem',
    },
    logo: {
      width: '100%',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      background: themeDDJJ.palette.secondary.main,
    },
    buttonProgress: {
      color: themeDDJJ.palette.secondary.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    link: {
      color: themeDDJJ.palette.secondary.main,
    },
    footerLogin: {
      color: 'white',
      '& p': {
        color: 'white',
      },
      '& a': {
        color: 'white',
      },
    },
  }));
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [recaptchaToken, setRecaptchaToken] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const classes = useStyles();

  async function loginUserExterno(credentials) {
    return fetch(Environment.apiUrl + '/login_externo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    })
      .then((data) => data.json())
      .catch((error) => {
        setError('Error');
        console.log(error);
      });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    const loginData = await loginUserExterno({
      email,
      password,
    });
    setLoading(false);
    if (loginData) {
      if (loginData.status === true) {
        setLoggedUser(loginData?.user);
        history.replace({ pathname: '/declaraciones_control_externo' });
      } else {
        setError(loginData?.message);
      }
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleRecaptcha = (val) => {
    setRecaptchaToken(val);
  };
  return (
    <Container
      component='main'
      maxWidth='xs'
      className={classes.loginContenedor}
    >
      <CssBaseline />
      <div className={classes.paper}>
        <img
          className={classes.logo}
          src='/images/logos/logo_login.png'
          alt='logo'
        />
        <h1 className={classes.title}>Autoridad de Aplicación del CMCABA</h1>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            type='email'
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email'
            name='email'
            autoComplete='email'
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label='Contraseña'
            type={showPassword ? 'text' : 'password'}
            id='password'
            autoComplete='current-password'
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div hidden={!error?.length}>
            <Alert variant='outlined' severity='error'>
              {error}
            </Alert>
          </div>
          <ReCAPTCHA
            sitekey={
              Environment.recaptchaKey ||
              '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
            }
            onChange={handleRecaptcha}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            disabled={loading || !recaptchaToken}
            className={classes.submit}
          >
            Ingresar
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                component='button'
                variant='body2'
                className={classes.link}
                onClick={(ev) => {
                  ev.preventDefault();
                  Swal.fire(
                    'Información',
                    'Para solicitar un cambio de contraseña deberá contactarse con el Ministerio Público Fiscal de la C.A.B.A.',
                    'info'
                  );
                }}
              >
                ¿Olvidaste la contraseña?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={3} className={classes.footerLogin}>
        <FooterCopyright />
      </Box>
    </Container>
  );
};

export default LoginExterno;
