import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Link from "@material-ui/core/Link";
import colors from "../themes/coloresDDJJ";


const useStyles = makeStyles({
  contenedorFooter: {
    paddingBottom: '20px',
    textAlign: 'center',
    color: colors.azul,
    '& p:first-child': {
      letterSpacing: '1px',
      fontSize: '1.1em',
      margin: '0',
    },
  },
});

function FooterCopyright() {
  const classes = useStyles();
  return (
    <div className={classes.contenedorFooter}>
      <p>MINISTERIO PÚBLICO FISCAL</p>
      <Link color="inherit" href="https://mpfciudad.gob.ar">
        Ciudad Autónoma de Buenos Aires
      </Link>
    </div>
  );
}

export default FooterCopyright;
