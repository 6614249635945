import { useState } from "react";
import { useLocation } from "react-router-dom";

export default function useFormulario() {
  const location = useLocation();
  const [formulario, setFormulario] = useState(location.state.formulario);
  return {
    setFormulario: setFormulario,
    formulario,
  };
}
