import { AES, enc } from "crypto-js";

import Environment from "../providers/Environment";

export default function useEncryptStorage(driver = sessionStorage) {
  const getItem = (key) => {
    const encryptedItem = driver.getItem(key);
    if (encryptedItem !== null) {
      const decrypted = AES.decrypt(encryptedItem, Environment.secretKey);
      return JSON.parse(decrypted.toString(enc.Utf8));
    }
    return null;
  };

  const setItem = (key, object) => {
    const encryptedItem = AES.encrypt(
      JSON.stringify(object),
      Environment.secretKey
    );
    driver.setItem(key, encryptedItem.toString());
  };

  const removeItem = (key) => {
    driver.removeItem(key);
  };

  return {
    getItem: getItem,
    setItem: setItem,
    removeItem: removeItem,
  };
}
