import { useHistory } from 'react-router-dom';

import Environment from '../providers/Environment';

import useLoggedUser from './useLoggedUser';
export default function useBackendApi() {
  const { loggedUser } = useLoggedUser();
  const history = useHistory();

  const responseParse = (res) => {
    if (res.ok === false) {
      if (res.status === 401) {
        //setLoggedUser(false);
        history.replace({ pathname: '/login' });
      }
    }
    return res.json();
  };

  const getDatosUsuarioMpf = (
    callback,
    callbackError = (err) => console.log(err)
  ) => {
    if (loggedUser === null || loggedUser === false) {
      history.replace({ pathname: '/login' });
      return;
    }
    const methodUrl = `${Environment.apiUrl}/1.0/datos_usuario/${loggedUser.username}`;
    return fetch(methodUrl, {
      headers: {
        Authorization: 'Bearer ' + loggedUser.api_token,
      },
    })
      .then(responseParse)
      .then(callback)
      .catch(callbackError);
  };

  const getDeclaracion = (
    id,
    callback,
    callbackError = (err) => console.log(err)
  ) => {
    if (loggedUser === null || loggedUser === false) {
      history.replace({ pathname: '/login' });
      return;
    }
    const methodUrl = `${Environment.apiUrl}/1.0/declaraciones/${id}`;
    return fetch(methodUrl, {
      headers: {
        Authorization: 'Bearer ' + loggedUser.api_token,
      },
    })
      .then(responseParse)
      .then(callback)
      .catch(callbackError);
  };

  const getDeclaracionesPendientes = (
    callback,
    callbackError = (err) => console.log(err)
  ) => {
    if (loggedUser === null || loggedUser === false) {
      history.replace({ pathname: '/login' });
      return;
    }
    const methodUrl = `${Environment.apiUrl}/1.0/declaraciones/pendientes/${loggedUser._id}`;
    return fetch(methodUrl, {
      headers: {
        Authorization: 'Bearer ' + loggedUser.api_token,
      },
    })
      .then(responseParse)
      .then(callback)
      .catch(callbackError);
  };

  const getDeclaracionesFinalizadas = (
    callback,
    callbackError = (err) => console.log(err)
  ) => {
    if (loggedUser === null || loggedUser === false) {
      history.replace({ pathname: '/login' });
      return;
    }
    const methodUrl = `${Environment.apiUrl}/1.0/declaraciones/finalizadas/${loggedUser._id}`;
    return fetch(methodUrl, {
      headers: {
        Authorization: 'Bearer ' + loggedUser.api_token,
      },
    })
      .then(responseParse)
      .then(callback)
      .catch(callbackError);
  };

  const getDeclaracionesControl = (
    callback,
    callbackError = (err) => console.log(err),
    anio
  ) => {
    if (loggedUser === null || loggedUser === false) {
      history.replace({ pathname: '/login' });
      return;
    }
    let methodUrl = `${Environment.apiUrl}/1.0/declaraciones/control`;
    if (anio) {
      methodUrl = `${Environment.apiUrl}/1.0/declaraciones/control/${anio}`;
    }
    return fetch(methodUrl, {
      headers: {
        Authorization: 'Bearer ' + loggedUser.api_token,
      },
    })
      .then(responseParse)
      .then(callback)
      .catch(callbackError);
  };

  const getDeclaracionesControlPaginacion = (
    callback,
    callbackError = (err) => console.log(err),
    query = {
      error: undefined,
      filters: [],
      orderBy: undefined,
      orderDirection: '',
      page: 1,
      pageSize: 15,
      search: '',
      totalCount: 0,
    }
  ) => {
    if (loggedUser === null || loggedUser === false) {
      history.replace({ pathname: '/login' });
      return;
    }
    const methodUrl = `${Environment.apiUrl}/1.0/declaraciones/control?page=${query.page}&limit=${query.pageSize}`;
    return fetch(methodUrl, {
      headers: {
        Authorization: 'Bearer ' + loggedUser.api_token,
      },
    })
      .then(responseParse)
      .then(callback)
      .catch(callbackError);
  };

  const deleteDeclaracion = (
    id,
    callback,
    callbackError = (err) => console.log(err)
  ) => {
    if (loggedUser === null || loggedUser === false) {
      history.replace({ pathname: '/login' });
      return;
    }
    const methodUrl = `${Environment.apiUrl}/1.0/declaraciones/delete`;
    const parametros = {
      id: id,
    };
    return fetch(methodUrl, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + loggedUser.api_token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(parametros),
    })
      .then(responseParse)
      .then(callback)
      .catch(callbackError);
  };

  const exportDeclaracion = (
    parametros,
    callback,
    callbackError = (err) => console.log(err)
  ) => {
    if (loggedUser === null || loggedUser === false) {
      history.replace({ pathname: '/login' });
      return;
    }
    const methodUrl = `${Environment.apiUrl}/1.0/declaraciones/export`;
    return fetch(methodUrl, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + loggedUser.api_token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(parametros),
    })
      .then(responseParse)
      .then(callback)
      .catch(callbackError);
  };

  const cloneDeclaracion = (
    parametros,
    callback,
    callbackError = (err) => console.log(err)
  ) => {
    if (loggedUser === null || loggedUser === false) {
      history.replace({ pathname: '/login' });
      return;
    }
    const methodUrl = `${Environment.apiUrl}/1.0/declaraciones/clone`;
    return fetch(methodUrl, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + loggedUser.api_token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(parametros),
    })
      .then(responseParse)
      .then(callback)
      .catch(callbackError);
  };

  const printDeclaracion = (
    id,
    pass = '',
    callback,
    callbackError = (err) => console.log(err)
  ) => {
    if (loggedUser === null || loggedUser === false) {
      history.replace({ pathname: '/login' });
      return;
    }
    let methodUrl = `${Environment.apiUrl}/1.0/declaraciones/${id}/print`;
    if (pass.length) {
      methodUrl += `/${pass}`;
    }
    return fetch(methodUrl, {
      headers: {
        Authorization: 'Bearer ' + loggedUser.api_token,
      },
    })
      .then(responseParse)
      .then(callback)
      .catch(callbackError);
  };

  const grabarFormulario = (
    parametros,
    callback,
    callbackError = (err) => console.log(err)
  ) => {
    if (loggedUser === null || loggedUser === false) {
      history.replace({ pathname: '/login' });
      return;
    }
    const methodUrl = `${Environment.apiUrl}/1.0/declaraciones_formularios`;
    return fetch(methodUrl, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + loggedUser.api_token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(parametros),
    })
      .then(responseParse)
      .then(callback)
      .catch(callbackError);
  };

  const getFormulariosControl = async (userId, anio) => {
    if (loggedUser === null || loggedUser === false) {
      history.replace({ pathname: '/login' });
      return;
    }
    const methodUrl = `${Environment.apiUrl}/1.0/declaraciones_formularios/${userId}/${anio}/download`;
    return fetch(methodUrl, {
      headers: {
        Authorization: 'Bearer ' + loggedUser.api_token,
      },
    }).then(responseParse);
  };

  return {
    getDatosUsuarioMpf: getDatosUsuarioMpf,
    getDeclaracion: getDeclaracion,
    getDeclaracionesPendientes: getDeclaracionesPendientes,
    getDeclaracionesFinalizadas: getDeclaracionesFinalizadas,
    deleteDeclaracion: deleteDeclaracion,
    exportDeclaracion: exportDeclaracion,
    printDeclaracion: printDeclaracion,
    cloneDeclaracion: cloneDeclaracion,
    grabarFormulario: grabarFormulario,
    getDeclaracionesControl: getDeclaracionesControl,
    getDeclaracionesControlPaginacion: getDeclaracionesControlPaginacion,
    getFormulariosControl: getFormulariosControl,
  };
}
