import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import { teal } from '@material-ui/core/colors';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import FingerprintIcon from '@material-ui/icons/Fingerprint';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LockIcon from '@material-ui/icons/Lock';

import Swal from 'sweetalert2';

import Environment from '../providers/Environment';

import tableConstants from '../hooks/tableConstants';
import colors from '../themes/coloresDDJJ';
import useLoggedUser from '../hooks/useLoggedUser';

import useBackendApi from '../hooks/useBackendApi';

import DeclaracionHeader from './DeclaracionHeader';
import DeclaracionTabla from './DeclaracionTabla';
import useDatosUsuario from '../hooks/useDatosUsuario';
import ContenidoLoading from './ContenidoLoading';

const DeclaracionEdicion = ({ declaracionId, classes }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [declaracion, setDeclaracion] = useState({
    declaraciones_formularios: [],
  });
  const loggedUser = useLoggedUser().loggedUser;
  const datosUsuario = useDatosUsuario().datosUsuario;
  const { getDeclaracion, grabarFormulario } = useBackendApi();

  useEffect(() => {
    setLoading(true);
    getDeclaracion(declaracionId, (res) => {
      if (res?.status === true && res?.data !== null) {
        setDeclaracion(res?.data);
      } else {
        Swal.fire(
          'Error',
          'No existe la declaración o no corresponde a su usuario',
          'error'
        ).then((res) => {
          history.replace({
            pathname: '/',
          });
        });
      }
      setLoading(false);
    });
  }, []);
  const columns = [
    { title: 'Id', field: '_id', hidden: true },
    {
      title: 'Título',
      field: 'titulo',
      render: (row) =>
        row.publico === false ? (
          <Box
            style={{
              color: colors.rojo,
              display: 'flex',
              verticalAlign: 'center',
            }}
          >
            {row.titulo}{' '}
            <Tooltip title='Formulario privado' placement='right'>
              <LockIcon
                style={{
                  color: colors.rojo,
                  fontSize: '18px',
                  marginLeft: '2px',
                }}
              />
            </Tooltip>
          </Box>
        ) : (
          <Box>{row.titulo}</Box>
        ),
    },
    { title: 'Estado', field: 'estado' },
  ];
  function handleEditClick(ev, row) {
    history.push({
      pathname: '/formularios/' + row._id,
      state: { declaracion: declaracion, formulario: row },
    });
  }
  function imprimirDocumento(id, pass = '') {
    let url =
      Environment.apiUrl + '/1.0/declaraciones_formularios/' + id + '/print';
    if (pass.length > 0) {
      url += '/' + pass;
    }
    return fetch(url, {
      headers: {
        Authorization: 'Bearer ' + loggedUser.api_token,
      },
    }).then((data) => data.json());
  }
  function handlePrintClick(ev, row) {
    if (row.publico === false) {
      let title = 'Descargar formulario privado';
      let label = 'PIN';
      let placeholder = 'Ingrese el PIN';
      let errorText =
        'El PIN ingresado es incorrecto o no posee certificado de firma';
      let maxlength = Environment.maxPasswordLength;
      if (datosUsuario.certificado === '') {
        label = 'Contraseña de Red';
        placeholder = 'Ingrese la Contraseña';
        errorText = 'La Contraseña ingresada es incorrecta';
      }
      Swal.fire({
        title: title,
        input: 'password',
        inputLabel: label,
        inputPlaceholder: placeholder,
        inputAttributes: {
          maxlength: maxlength,
          autocapitalize: 'off',
          autocorrect: 'off',
        },
      }).then((res) => {
        if (res.value && res.value.length) {
          setLoading(true);
          fetch(Environment.apiUrl + '/1.0/firma/validar_pin_password', {
            method: 'POST',
            headers: {
              Authorization: 'Bearer ' + loggedUser.api_token,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              cert: datosUsuario.certificado,
              cert_pass: btoa(res.value),
            }),
          })
            .then((data) => data.json())
            .catch((err) => setLoading(false))
            .then((validation) => {
              setLoading(false);
              if (validation?.status) {
                setLoading(true);
                imprimirDocumento(row._id, btoa(res.value)).then((res) => {
                  setLoading(false);
                  if (res?.status === true) {
                    const linkSource = `data:application/pdf;base64,${res?.file}`;
                    const downloadLink = document.createElement('a');
                    const fileName = row.titulo + '.pdf';
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                  }
                });
              } else {
                Swal.fire('Error en validación', errorText, 'error');
              }
            });
        }
      });
    } else {
      setLoading(true);
      imprimirDocumento(row._id).then((res) => {
        setLoading(false);
        if (res?.status === true) {
          const linkSource = `data:application/pdf;base64,${res?.file}`;
          const downloadLink = document.createElement('a');
          const fileName = row.titulo + '.pdf';
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      });
    }
  }
  const actions = [
    (row) => ({
      icon: tableConstants.icons.Edit,
      tooltip: 'Editar',
      hidden: row.estado === 'Firmado' || row.estado === 'Para Presentar',
      disabled: row.estado === 'Firmado' || row.estado === 'Para Presentar',
      onClick: handleEditClick,
    }),
    (row) => ({
      icon: tableConstants.icons.Print,
      tooltip: 'Descargar',
      onClick: handlePrintClick,
    }),
    (row) => ({
      icon: tableConstants.icons.Restore,
      tooltip: 'Rectificar / Cambiar',
      hidden: row.estado === 'Borrador' || row.estado === 'Firmado',
      disabled: row.estado === 'Borrador' || row.estado === 'Firmado',
      onClick: handleRectificarClick,
    }),
  ];
  const options = {
    actionsColumnIndex: -1,
    headerStyle: {
      backgroundColor: colors.azul,
      color: '#ffffff',
    },
    paging: false,
    search: false,
  };
  const theme = createMuiTheme({
    palette: {
      primary: teal,
    },
  });
  const useStyles = makeStyles((theme) => ({
    contenedorBotones: {
      background: '#f0f0f0',
      display: 'flex',
      justifyContent: 'center',
      padding: '20px',
    },
    button: {
      margin: theme.spacing(1),
    },
  }));
  const classesAcciones = useStyles();
  const handleRectificarClick = (e, row) => {
    e.preventDefault();
    Swal.fire({
      title: '<strong>¿Está seguro?</strong>',
      icon: 'warning',
      html: "Esta acción volverá el formulario a <b>'Borrador'</b> y permitirá editarlo nuevamente",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Rectificar/Cambiar',
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const formularios = [...declaracion.declaraciones_formularios];
        const formularioIndex = formularios.findIndex(
          (formulario) => formulario._id === row._id
        );
        formularios[formularioIndex].estado = 'Borrador';
        grabarFormulario(
          formularios[formularioIndex],
          (response) => {
            setLoading(false);
            Swal.fire(
              response.status === true ? 'Realizado' : 'Error',
              response.status === true
                ? '¡Formulario nuevamente en borrador!'
                : 'Ocurrió un error grabando el formulario, intente nuevamente.',
              response.status === true ? 'success' : 'error'
            ).then(() => {
              setDeclaracion({
                ...declaracion,
                declaraciones_formularios: formularios,
              });
            });
          },
          (error) => {
            console.error('Error:', error);
            setLoading(false);
          }
        );
      }
    });
  };
  /*
  const handleCerrarFirmar = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "<strong>¿Está seguro?</strong>",
      icon: "warning",
      html: "Si <b>cierra y firma</b> la declaración no podrá volver a editarla de aquí en adelante",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        let title = "CERRAR Y FIRMAR DECLARACIÓN";
        let label = "Ingrese su PIN del certificado de firma";
        let placeholder = "Ingrese el PIN";
        let maxlength = Environment.maxPasswordLength;
        if (datosUsuario.certificado === "") {
          label = "Ingrese su contraseña de red";
          placeholder = "Ingrese la contraseña";
        }
        Swal.fire({
          title: title,
          input: "password",
          inputLabel: label,
          inputPlaceholder: placeholder,
          confirmButtonText: "Firmar",
          inputAttributes: {
            maxlength: maxlength,
            autocapitalize: "off",
            autocorrect: "off",
          },
        }).then((res) => {
          if (res.value && res.value.length) {
            setLoading(true);
            const parametros = {
              id: declaracion._id,
              datos_usuario: datosUsuario,
              cert_pass: btoa(res.value),
            };
            fetch(Environment.apiUrl + "/1.0/declaraciones/close_sign", {
              method: "POST",
              headers: {
                Authorization: "Bearer " + loggedUser.api_token,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(parametros),
            })
              .then((data) => data.json())
              .catch((error) => {
                console.error("Error:", error);
                Swal.fire("Error", error.message, "error");
                setLoading(false);
              })
              .then((response) => {
                setLoading(false);
                Swal.fire(
                  response.status === true ? "Realizado" : "Error",
                  response.status === true
                    ? "¡Declaración cerrada y firmada correctamente!"
                    : "Ocurrió un error cerrando y firmando la declaración, intente nuevamente. (REF:  " +
                    response.message +
                    ")",
                  response.status === true ? "success" : "error"
                ).then((s) => {
                  history.replace({
                    pathname: "/",
                  });
                });
              });
          }
        });
      }
      return;
    });
  };
  */
  const handleCancelar = (e) => {
    e.preventDefault();
    history.replace({
      pathname: '/',
    });
  };
  return (
    <>
      {loading ? (
        <ContenidoLoading />
      ) : (
        <Container maxWidth='lg'>
          <DeclaracionHeader declaracion={declaracion} />
          <Grid container>
            <Grid item xs={12} className={classes.bloques}>
              <DeclaracionTabla
                title='Formularios'
                columns={columns}
                actions={actions}
                options={options}
                data={declaracion?.declaraciones_formularios}
              />
            </Grid>
          </Grid>
          <Box className={classesAcciones.contenedorBotones}>
            <Button
              className={classesAcciones.button}
              onClick={handleCancelar}
              startIcon={<ArrowBackIcon />}
              size='large'
            >
              Volver
            </Button>
            {/*
            <ThemeProvider theme={theme}>
              {declaracion?.estado !== "Finalizada" ? (
                <Button
                  variant="contained"
                  color="primary"
                  className={classesAcciones.button}
                  disabled={
                    !declaracion?.declaraciones_formularios.length ||
                    !declaracion?.declaraciones_formularios.every(
                      (elem) => elem.estado === "Para Presentar"
                    )
                  }
                  onClick={handleCerrarFirmar}
                  startIcon={<FingerprintIcon />}
                  size="large"
                >
                  Cerrar y firmar declaración
                </Button>
              ) : (
                <></>
              )}
            </ThemeProvider>
            */}
          </Box>
        </Container>
      )}
    </>
  );
};

export default DeclaracionEdicion;
