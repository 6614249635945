import { createMuiTheme } from '@material-ui/core/styles';

const themeDDJJ = createMuiTheme({
  palette: {
    primary: {
      light: '#309A80',
      main: '#008072',
      dark: '#00695f',
      contrastText: '#FFF',
    },
    secondary: {
      light: '#2272CE',
      main: '#006370',
      dark: '#00414A',
      contrastText: '#FFF',
    },
  },
});
export default themeDDJJ;