import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';

import HomeIcon from '@material-ui/icons/Home';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import Swal from 'sweetalert2';

import Environment from '../providers/Environment';

import colors from '../themes/coloresDDJJ';
import useLoggedUser from '../hooks/useLoggedUser';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #DDD',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: colors.azul,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function HeaderOpciones({ datosUsuario }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const { loggedUser, setLoggedUser } = useLoggedUser();
  const history = useHistory();
  const handleLogout = () => {
    const replaceObj = loggedUser.externo
      ? { pathname: '/login_externo' }
      : { pathname: '/login' };
    setLoggedUser(false);
    history.replace(replaceObj);
  };

  const handleHome = () => {
    history.replace({ pathname: '/' });
  };
  const handleControl = () => {
    history.replace({ pathname: '/declaraciones_control' });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleValidatePin = () => {
    let title = 'Validación de PIN';
    let label = 'PIN';
    let placeholder = 'Ingrese el PIN';
    let successText = 'El PIN ingresado es correcto';
    let errorText =
      'El PIN ingresado es incorrecto o no posee certificado de firma';
    let maxlength = Environment.maxPasswordLength;
    if (datosUsuario.certificado === '') {
      title = 'Validación de Contraseña';
      label = 'Contraseña de Red';
      placeholder = 'Ingrese la Contraseña';
      successText = 'La Contraseña ingresada es correcta';
      errorText = 'La Contraseña ingresada es incorrecta';
    }
    Swal.fire({
      title: title,
      input: 'password',
      inputLabel: label,
      inputPlaceholder: placeholder,
      inputAttributes: {
        maxlength: maxlength,
        autocapitalize: 'off',
        autocorrect: 'off',
      },
    }).then((res) => {
      if (res.value && res.value.length) {
        setLoading(true);
        fetch(Environment.apiUrl + '/1.0/firma/validar_pin_password', {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + loggedUser.api_token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            cert: datosUsuario.certificado,
            cert_pass: btoa(res.value),
          }),
        })
          .then((data) => data.json())
          .catch((err) => setLoading(false))
          .then((validation) => {
            setLoading(false);
            if (validation?.status) {
              Swal.fire('¡Validación exitosa!', successText, 'success');
            } else {
              Swal.fire('Error en validación', errorText, 'error');
            }
          });
      } else {
        Swal.fire(
          'Error en validación',
          'Tiene que ingresar el PIN del certificado de firma',
          'error'
        );
      }
    });
  };

  return (
    <div>
      {loading ? (
        <CircularProgress disableShrink />
      ) : (
        <>
          <IconButton
            aria-controls='customized-menu'
            aria-haspopup='true'
            variant='contained'
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          {datosUsuario ? (
            <StyledMenu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <StyledMenuItem onClick={handleHome}>
                <ListItemIcon>
                  <HomeIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText primary='Inicio' />
              </StyledMenuItem>
              <StyledMenuItem onClick={handleValidatePin}>
                <ListItemIcon>
                  <VpnKeyIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText primary='Validar credenciales de firma' />
              </StyledMenuItem>
              {loggedUser.roles.filter((role) => {
                return (
                  role.key === Environment.rolAdministrador ||
                  role.key === Environment.rolMesaEntradas
                );
              }).length ? (
                <StyledMenuItem onClick={handleControl}>
                  <ListItemIcon>
                    <PlaylistAddCheckIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText primary='Listado de Control' />
                </StyledMenuItem>
              ) : (
                <div></div>
              )}
              <StyledMenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <ExitToAppIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText primary='Salir' />
              </StyledMenuItem>
            </StyledMenu>
          ) : (
            // Externos
            <StyledMenu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <StyledMenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <ExitToAppIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText primary='Salir' />
              </StyledMenuItem>
            </StyledMenu>
          )}
        </>
      )}
    </div>
  );
}
