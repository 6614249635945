import { useState } from "react";

import useEncryptStorage from "./useEncryptStorage";

export default function useDatosUsuario() {
  const encryptStorage = useEncryptStorage();

  const getDatosUsuario = () => {
    const datos = encryptStorage.getItem("datosUsuario");
    return JSON.parse(datos);
  };
  const [datosUsuario, setDatosUsuario] = useState(getDatosUsuario());

  const saveDatosUsuario = (datos) => {
    if (!datos) {
      encryptStorage.removeItem("datosUsuario");
    } else {
      encryptStorage.setItem("datosUsuario", JSON.stringify(datos));
    }
    setDatosUsuario(datos);
  };

  return {
    datosUsuario,
    setDatosUsuario: saveDatosUsuario,
  };
}
