import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import themeDDJJ from '../themes/themeDDJJ';

import Swal from 'sweetalert2';

import Environment from '../providers/Environment';

import useLoggedUser from '../hooks/useLoggedUser';

const DeclaracionNueva = ({ setValue, datosUsuario }) => {
  const useStyles = makeStyles((theme) => ({
    submitProgress: {
      color: themeDDJJ.palette.secondary.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }));
  const classes = useStyles();
  const loggedUser = useLoggedUser().loggedUser;
  const [loading, setLoading] = useState(false);
  const [falla, setFalla] = useState({
    mensaje: '',
    errores: {},
  });

  const [nuevaPresentacion, setNuevaPresentacion] = useState({
    tipo: '',
    propia: true,
    tipoDocumento: '',
    numeroDocumento: '',
    cuil: '',
    legajo: '',
    vinculo: '',
    familiarRelacionado: {},
    nombre: '',
    apellido: '',
    nombreCompleto: '',
    anio: new Date().getFullYear(),
    fechaCierre: new Date(new Date().getFullYear() - 1, 11, 31),
    cargoFuncion: '',
    dependencia: '',
    estadoCivil: '',
  });
  const tipos = [
    { title: 'ANUAL', value: 'ANUAL' },
    { title: 'CESE', value: 'CESE' },
    { title: 'ALTA', value: 'ALTA' },
    { title: 'ACTUALIZACIÓN', value: 'ACTUALIZACION' },
  ];
  const estadosCiviles = [
    { title: 'SOLTERA/O', value: 'SOLTERA/O' },
    { title: 'CASADA/O', value: 'CASADA/O' },
    { title: 'DIVORCIADA/O', value: 'DIVORCIADA/O' },
    { title: 'VIUDA/O', value: 'VIUDA/O' },
    { title: 'CONCUBINA/O', value: 'CONCUBINA/O' },
  ];
  const handleChangePropia = (ev, val) => {
    let nombre = '';
    let apellido = '';
    let nombreCompleto = '';
    let legajo = '';
    let tipoDocumento = '';
    let numeroDocumento = '';
    let cuil = '';
    let cargoFuncion = '';
    let dependencia = '';
    if (val === true) {
      nombre = datosUsuario?.nombre;
      apellido = datosUsuario?.apellido;
      nombreCompleto = nombre + ' ' + apellido;
      tipoDocumento = datosUsuario?.tipo_documento;
      numeroDocumento = datosUsuario?.numero_documento;
      legajo = datosUsuario?.legajo;
      cuil = datosUsuario?.cuil;
      cargoFuncion = datosUsuario?.cargo;
      dependencia = datosUsuario?.dependencia.aal_nombre
        ? datosUsuario?.dependencia.aal_nombre
        : datosUsuario?.dependencia;
    }
    setNuevaPresentacion({
      ...nuevaPresentacion,
      propia: val,
      nombre: nombre,
      apellido: apellido,
      nombreCompleto: nombreCompleto,
      legajo: legajo,
      tipoDocumento: tipoDocumento,
      numeroDocumento: numeroDocumento,
      cargoFuncion: cargoFuncion,
      dependencia: dependencia,
      cuil: cuil,
      vinculo: '',
      familiarRelacionado: {},
    });
  };
  const handleChangeFamiliarRelacionado = (ev, obj) => {
    let nombre_completo = obj?.nombre_completo;
    if (nombre_completo === null || nombre_completo === undefined) {
      nombre_completo = ',';
    }
    const [apellido, nombre] = nombre_completo.split(',');
    setNuevaPresentacion({
      ...nuevaPresentacion,
      nombre: nombre,
      apellido: apellido,
      nombreCompleto: nombre + ' ' + apellido,
      legajo: obj?.legajo ? obj.legajo : '',
      vinculo: obj?.parentesco,
      familiarRelacionado: obj !== null && obj !== undefined ? obj : {},
      tipoDocumento: obj?.tipo_documento,
      numeroDocumento: obj?.numero_documento,
      dependencia: '',
      cuil: obj?.cuil,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setFalla({
      mensaje: '',
      errores: {},
    });
    const declaracion = {};
    for (let key in nuevaPresentacion) {
      declaracion[key.replace(/([A-Z])/g, '_$1').toLowerCase()] =
        nuevaPresentacion[key];
    }
    fetch(Environment.apiUrl + '/1.0/declaraciones', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + loggedUser.api_token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(declaracion),
    })
      .then((data) => data.json())
      .catch((error) => {
        setLoading(false);
        console.error('Error:', error);
        Swal.fire(
          'Error en creación',
          'Ocurrió un error creando la Declaración, intente nuevamente',
          'error'
        );
      })
      .then((response) => {
        setLoading(false);
        if (response?.status === true && response?.data) {
          setValue(1); // pasa a Tab 1.
        } else {
          const nFalla = { mensaje: response.message, errores: {} };
          nFalla.errores = response.validation;
          setFalla(nFalla);
        }
      });
  };

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit}>
      <Container maxWidth='lg'>
        <Typography variant='h6' gutterBottom>
          Nueva Declaración
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Autocomplete
              id='tipo'
              name='tipo'
              options={tipos}
              getOptionLabel={(option) => option.title}
              getOptionSelected={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  error={falla.errores?.tipo ? true : false}
                  helperText={falla.errores?.tipo ? 'Campo requerido' : null}
                  {...params}
                  required
                  label='Tipo'
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              noOptionsText='No hay opciones'
              onChange={(event, obj) => {
                setNuevaPresentacion({
                  ...nuevaPresentacion,
                  tipo: obj?.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              id='propia'
              name='propia'
              options={[true, false]}
              getOptionLabel={(option) => (option === true ? 'SI' : 'NO')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={falla.errores?.propia ? true : false}
                  helperText={falla.errores?.propia ? 'Campo requerido' : null}
                  required
                  label='Propia'
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              noOptionsText='No hay opciones'
              onChange={handleChangePropia}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              id='anio'
              name='anio'
              label='Año'
              type='number'
              error={falla.errores?.anio ? true : false}
              helperText={falla.errores?.anio ? 'Campo requerido' : null}
              required
              defaultValue={new Date().getFullYear()}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              onChange={(event) => {
                setNuevaPresentacion({
                  ...nuevaPresentacion,
                  anio: event.target.value,
                  fechaCierre: new Date(event.target.value - 1, 11, 31),
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id='fecha_cierre'
              name='fecha_cierre'
              label='Fecha Cierre'
              type='date'
              value={nuevaPresentacion.fechaCierre?.toISOString().slice(0, 10)}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems='flex-end'>
          <Grid item xs={12}>
            <Autocomplete
              id='familiar_relacionado'
              name='familiar_relacionado'
              options={datosUsuario?.familiares || []}
              // value={datosUsuario?.familiarRelacionado}
              getOptionLabel={(option) =>
                option?.cuil +
                ' - ' +
                option?.parentesco +
                ' - ' +
                option?.nombre_completo
              }
              getOptionSelected={(option, value) => option.cuil === value.cuil}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Familiar'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText='Si el familar no aparece listado aquí es porque no está cargado en el sistema OMBU. Para cargar una declaración, de todos modos, deje este campo vacío y cargue los campos con la información correspondiente al familiar en cuestión.'
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              noOptionsText='No hay opciones'
              onChange={handleChangeFamiliarRelacionado}
              hidden={
                nuevaPresentacion?.propia === true ||
                nuevaPresentacion?.propia === null
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <TextField
              id='nombre_completo'
              name='nombre_completo'
              label='Nombre Completo'
              error={falla.errores?.tipo ? true : false}
              helperText={falla.errores?.tipo ? 'Campo requerido' : null}
              type='text'
              value={nuevaPresentacion?.nombreCompleto}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                setNuevaPresentacion({
                  ...nuevaPresentacion,
                  nombreCompleto: event.target.value,
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              id='estado_civil'
              name='estado_civil'
              options={estadosCiviles}
              getOptionLabel={(option) => option.title}
              getOptionSelected={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={falla.errores?.estado_civil ? true : false}
                  helperText={
                    falla.errores?.estado_civil ? 'Campo requerido' : null
                  }
                  required
                  label='Estado Civil'
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              noOptionsText='No hay opciones'
              onChange={(event, obj) => {
                setNuevaPresentacion({
                  ...nuevaPresentacion,
                  estadoCivil: obj?.value,
                });
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <TextField
              id='tipo_documento'
              name='tipo_documento'
              label='Tipo Documento'
              type='text'
              required
              error={falla.errores?.tipo_documento ? true : false}
              helperText={
                falla.errores?.tipo_documento ? 'Campo requerido' : null
              }
              value={nuevaPresentacion?.tipoDocumento}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly:
                  nuevaPresentacion.propia ||
                  (!nuevaPresentacion.propia &&
                    Object.keys(nuevaPresentacion?.familiarRelacionado).length >
                      0),
              }}
              onChange={(event) => {
                setNuevaPresentacion({
                  ...nuevaPresentacion,
                  tipoDocumento: event.target.value,
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id='numero_documento'
              name='numero_documento'
              label='Nro. Documento'
              type='text'
              required
              error={falla.errores?.numero_documento ? true : false}
              helperText={
                falla.errores?.numero_documento ? 'Campo requerido' : null
              }
              value={nuevaPresentacion?.numeroDocumento}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly:
                  nuevaPresentacion.propia ||
                  (!nuevaPresentacion.propia &&
                    Object.keys(nuevaPresentacion?.familiarRelacionado).length >
                      0),
              }}
              onChange={(event) => {
                setNuevaPresentacion({
                  ...nuevaPresentacion,
                  numeroDocumento: event.target.value,
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id='cuil'
              name='cuil'
              label='CUIL / CUIT'
              type='text'
              value={nuevaPresentacion?.cuil}
              error={falla.errores?.cuil ? true : false}
              helperText={falla.errores?.cuil ? 'Campo requerido' : null}
              required
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly:
                  nuevaPresentacion.propia ||
                  (!nuevaPresentacion.propia &&
                    Object.keys(nuevaPresentacion?.familiarRelacionado).length >
                      0),
              }}
              onChange={(event) => {
                setNuevaPresentacion({
                  ...nuevaPresentacion,
                  cuil: event.target.value,
                });
              }}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <TextField
              id='cargo_funcion'
              name='cargo_funcion'
              label='Cargo / Función Completo'
              type='text'
              error={falla.errores?.cargo_funcion ? true : false}
              helperText={
                falla.errores?.cargo_funcion ? 'Campo requerido' : null
              }
              value={nuevaPresentacion?.cargoFuncion}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: nuevaPresentacion.propia,
              }}
              onChange={(event) => {
                setNuevaPresentacion({
                  ...nuevaPresentacion,
                  cargoFuncion: event.target.value,
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              id='legajo'
              name='legajo'
              label='Legajo'
              type='text'
              value={nuevaPresentacion?.legajo}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: nuevaPresentacion.propia,
              }}
              onChange={(event) => {
                setNuevaPresentacion({
                  ...nuevaPresentacion,
                  legajo: event.target.value,
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              id='vinculo'
              name='vinculo'
              label='Vínculo'
              type='text'
              helperText='Completar en el supuesto de familiares obligados'
              value={nuevaPresentacion?.vinculo}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly:
                  nuevaPresentacion.propia ||
                  (!nuevaPresentacion.propia &&
                    Object.keys(nuevaPresentacion?.familiarRelacionado).length >
                      0),
              }}
              onChange={(event) => {
                setNuevaPresentacion({
                  ...nuevaPresentacion,
                  vinculo: event.target.value,
                });
              }}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              disabled={loading}
              className={classes.submit}
            >
              Iniciar declaración
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.submitProgress}
                />
              )}
            </Button>
          </Grid>
        </Grid>

        <div
          hidden={
            !falla || !falla.errores || Object.keys(falla.errores).length === 0
          }
        >
          <Snackbar
            open
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert severity='error' variant='filled'>
              {falla?.mensaje}
            </Alert>
          </Snackbar>
        </div>
      </Container>
    </form>
  );
};

export default DeclaracionNueva;
