const Environment = {
  apiUrl: process.env.REACT_APP_API_URL,
  secretKey: process.env.REACT_APP_SECRET_KEY,
  recaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY,
  leyendaEncriptacion:
    process.env.REACT_APP_LEYENDA_ENCRIPTACION ||
    'Todos los datos cargados son almacenados y encriptados en una base de datos administrada exclusivamente por el MPFCIUDAD',
  leyendaNoCertificado:
    process.env.REACT_APP_LEYENDA_NO_CERTIFICADO ||
    'Usted no tiene cargado ningún certificado de firma electrónico en el Sistema OMBU, esto no le permitirá cerrar y finalizar las declaraciones debido a que las mismas requieren tener firma electrónica. Por favor, contacte al Departamento de Administración de Personal para realizar su gestión.',
  rolMesaEntradas:
    process.env.REACT_APP_ROL_MESA_ENTRADAS || 'ADMINISTRADOR_GENERAL',
  rolAdministrador: process.env.REACT_APP_ROL_ADMIN || 'MESA_DE_ENTRADAS',
  maxPasswordLength: process.env.REACT_APP_MAX_PASS_LENGTH || 50,
};

export default Environment;
