import React from 'react';
import { useHistory } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import Tooltip from '@material-ui/core/Tooltip';
import LockIcon from '@material-ui/icons/Lock';
import colors from '../themes/coloresDDJJ';

import HeaderOpciones from './HeaderOpciones';

import useLoggedUser from '../hooks/useLoggedUser';
import Environment from '../providers/Environment';

const Header = ({ classes, datosUsuario }) => {
  const loggedUser = useLoggedUser().loggedUser;
  const history = useHistory();
  const handleHeaderClick = () => {
    loggedUser.externo
      ? history.replace({ pathname: '/declaraciones_control_externo' })
      : history.replace({ pathname: '/' });
  };
  return (
    <>
      <CssBaseline />
      <AppBar
        position='absolute'
        color='transparent'
        className={classes.appBar}
      >
        <Toolbar className={classes.toolbar}>
          <div
            component='h1'
            variant='h6'
            color='inherit'
            className={classes.title}
            onClick={handleHeaderClick}
          >
            <img src='/images/logos/logo_header1.png' alt='logo' />
            {/* <img src="/images/logos/logo_header2.png" alt="logo" /> */}
          </div>
          <div style={{ position: 'absolute', left: '265px', top: '15px' }}>
            <Tooltip title={Environment.leyendaEncriptacion} placement='bottom'>
              <LockIcon
                style={{
                  color: colors.azul,
                  fontSize: '36px',
                  backgroundColor: '#FFFFFF',
                  padding: '8px',
                  borderRadius: '50%',
                  boxShadow: '0 0 3px #5dab7b',
                }}
              />
            </Tooltip>
          </div>
          <Typography component='h1' variant='h5' className={classes.user}>
            {loggedUser?.fullname}
          </Typography>
          <HeaderOpciones datosUsuario={datosUsuario} />
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
