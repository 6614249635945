import { forwardRef } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Visibility from "@material-ui/icons/Visibility";
import Print from "@material-ui/icons/Print";
import ArchiveIcon from "@material-ui/icons/Archive";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import colors from "../themes/coloresDDJJ";

const localization = {
  body: {
    emptyDataSourceMessage: "Sin registros",
    addTooltip: "Agregar registro",
    deleteTooltip: "Eliminar",
    editTooltip: "Editar",
    filterRow: {
      filterTooltip: "Filtro",
    },
    editRow: {
      saveTooltip: "Grabar",
      cancelTooltip: "Cancelar",
      deleteText: "¿Está seguro que desea eliminar esta fila?",
    },
  },
  header: {
    actions: "Acciones",
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} de {count}",
    labelRowsSelect: "filas",
    labelRowsPerPage: "Filas por página:",
    firstAriaLabel: "Primer página",
    firstTooltip: "Primer página",
    previousAriaLabel: "Página Anterior",
    previousTooltip: "Página Anterior",
    nextAriaLabel: "Siguiente Página",
    nextTooltip: "Siguiente Página",
    lastAriaLabel: "Última página",
    lastTooltip: "Última página",
  },
  toolbar: {
    nRowsSelected: "{0} fila(s) seleccionada(s)",
    exportTitle: "Exportar",
    exportAriaLabel: "Exportar",
    exportName: "Exportar a CSV",
    searchTooltip: "Buscar",
    searchPlaceholder: "Buscar",
  },
};
const tableIcons = {
  Add: forwardRef((props, ref) => (
    <AddCircleIcon style={{ color: colors.verde_claro }} {...props} ref={ref} />
  )),
  Check: forwardRef((props, ref) => (
    <Check style={{ color: colors.verde_claro }} {...props} ref={ref} />
  )),
  Clear: forwardRef((props, ref) => (
    <Clear style={{ color: colors.rojo }} {...props} ref={ref} />
  )),
  Delete: forwardRef((props, ref) => (
    <Delete style={{ color: colors.rojo }} {...props} ref={ref} />
  )),
  DeleteDisabled: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => (
    <Edit style={{ color: colors.verde_claro }} {...props} ref={ref} />
  )),
  EditDisabled: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Visibility: forwardRef((props, ref) => (
    <Visibility style={{ color: colors.verde }} {...props} ref={ref} />
  )),
  Print: forwardRef((props, ref) => (
    <Print style={{ color: colors.azul }} {...props} ref={ref} />
  )),
  Archive: forwardRef((props, ref) => (
    <ArchiveIcon style={{ color: colors.azul }} {...props} ref={ref} />
  )),
  Duplicate: forwardRef((props, ref) => (
    <FileCopyIcon style={{ color: colors.verde }} {...props} ref={ref} />
  )),
  Restore: forwardRef((props, ref) => <RotateLeftIcon style={{ color: colors.naranja }} {...props} ref={ref} />),
};

const tableConstants = {
  localization: localization,
  icons: tableIcons,
};

export default tableConstants;
