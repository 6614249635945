import React from "react";

import tableConstants from "../hooks/tableConstants";

import MaterialTable from "material-table";

const DeclaracionTabla = ({ title, columns, actions, options, data }) => {
  return (
    <MaterialTable
      title={title}
      icons={tableConstants.icons}
      columns={columns}
      data={data}
      actions={actions}
      options={options}
      localization={tableConstants.localization}
    />
  );
};

export default DeclaracionTabla;
